
import en from './en.json'
import ar from './ar.json'
// Ready translated locale messages
const translations = {
    en,
    ar
}

export default translations
