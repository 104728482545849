import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import auth from "./modules/auth.js";
import login from "./modules/login.js";
import global from "./modules/global.js";
import admins from "./modules/admins.js";
import categories from "./modules/categories.js";
import apps from "./modules/apps.js";
import autoupdates from "./modules/autoupdates.js";

const store = new Vuex.Store({
    modules: {
        auth,
        login,
        global,
        admins,
        categories,
        apps,
        autoupdates,
    }
});


export default store;
