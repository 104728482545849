require('./bootstrap');

import Vue from "vue";
import App from "./App.vue";
import store from './store/index'
import router from './router/router'

import axios from 'axios'

import vSelect from 'vue-select'

import $, { data } from 'jquery'
Vue.component('v-select', vSelect)

import StepProgress from 'vue-step-progress';
Vue.component('step-progress', StepProgress)

const ActionButton = () => import('./Components/Global/ActionButton.vue')
Vue.component('ActionButton', ActionButton)

const DeleteConfirm = () => import('./Components/Global/DeleteConfirm.vue')
Vue.component('DeleteConfirm', DeleteConfirm)

const Button = () => import('./Components/Global/Button.vue')
Vue.component('Button', Button)

const SuccessAlert = () => import('./Components/Global/SuccessAlert.vue')
Vue.component('SuccessAlert', SuccessAlert)



// Bootstrap VUE

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Translations
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import translation from './translations/index'

// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: 'en', // set locale
    messages: translation, // set locale messages
})

const mixins = {
    methods: {
        updateArrayObject(oldObject, updatedObject) {
            for (let i in oldObject) {
                oldObject[i] = updatedObject[i];
            }
            return oldObject;
        },
        handleResponseError(catchError) {
            if (catchError.response?.status == 404) {
                this.showRequestNotFoundError();
            }
            if (catchError.response?.status == 401) {
                // Unauthenticated
                this.logout();
            }
        },
        checkResposeErrorMessage(responseObject) {
            // there is an error
            if (responseObject.data.message == "Token error") {
                this.logout();
            }
            if (responseObject.data.message == "expired") {
                this.goToDashboard();
            }
        },

        fillErrorsArray(responseData) {
            let errorsArray = []
            if (typeof responseData.errors == "string") {
                errorsArray.push(responseData.errors);
            } else {
                for (const [key, value] of Object.entries(responseData.errors)) {
                    errorsArray.push(...value);
                }
            }
            return errorsArray
        },

        logout() {
            localStorage.removeItem("qs_market_token");
            localStorage.removeItem("qs_market_lastVisitedRoute");

            this.token = null;
            this.redirectWindowTo("/dashboard-login")

        },
        showRequestNotFoundError() {
            store._modules.root.context.state.global.isRequestNotFound = true
            setTimeout(() => {
                store._modules.root.context.state.global.isRequestNotFound = false
            }, 5000);
        },
        redirectWindowTo(url) {
            window.location.href = window.location.origin + url;
        },
        disableAllSubmitButtons(disableButtons) {
            let allButtons = $('button[type=submit]')
            for (let index = 0; index < allButtons.length; index++) {
                const element = allButtons[index];
                if (disableButtons) {
                    $(element).attr('disabled', true)
                } else {
                    $(element).removeAttr('disabled')
                }
            }
        },
        // AXIOS handled requests
        axiosRequest(type, request, successCallback) {
            let canRequest = true

            if (type != 'get') {

                canRequest = store._modules.root.context.state.global.isSubmitActive
                this.disableAllSubmitButtons(true)
            }
            if (canRequest) {
                store._modules.root.context.state.global.isSubmitActive = false
                axios({
                    method: type,
                    url: request.url,
                    data: request.data,
                    headers: {
                        Authorization: `Bearer ${store.getters["auth/getAdminToken"]}`,
                    },
                }).then(res => {
                    successCallback(res);
                }).catch((catchError) => {
                    this.handleResponseError(catchError)
                }).finally(() => {
                    store._modules.root.context.state.global.isSubmitActive = true
                    this.disableAllSubmitButtons(false)

                })
            }
        },

        axiosRequestWithFiles(type, request, successCallback) {
            let canRequest = true

            if (type != 'get') {

                canRequest = store._modules.root.context.state.global.isSubmitActive
                console.warn("canRequest: ", canRequest);
            }
            if (canRequest) {
                store._modules.root.context.state.global.isSubmitActive = false
                let formData = new FormData();
                // formData.append("test", 1);

                let dataKeys = Object.keys(request.data)
                let dataValues = Object.values(request.data)
                // console.warn("data: ", dataKeys);
                // console.warn("data: ", dataValues);
                let handledData = {}
                for (let index = 0; index < dataKeys.length; index++) {
                    const element = dataKeys[index];
                    const elementValue = dataValues[index];
                    if (element == 'images') {
                        for (let j = 0; j < elementValue.length; j++) {
                            const imageValue = elementValue[j];
                            formData.append('images[]', imageValue);
                        }
                    } else {

                        formData.append(element, elementValue);
                    }
                }


                // console.warn("element: ", element);
                //     handledData[`${element}`] = dataValues[index]
                //     handledData[`test`] = dataValues[index]

                axios({
                    method: type,
                    url: request.url,
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${store.getters["auth/getAdminToken"]}`,
                        'Content-Type': 'multipart/form-data'
                    },
                }).then(res => {

                    successCallback(res);
                }).catch((catchError) => {
                    this.handleResponseError(catchError)
                }).finally(() => {
                    store._modules.root.context.state.global.isSubmitActive = true
                })
            }
        },
        getObjectKeys(object) {
            return Object.keys(object);
        },
        getObjectValues(object) {
            return Object.values(object);
        },
        changeSelectedLanguage(language) {
            // /api/changelang

            // ----- TESTING --------------
            this.$i18n.locale = language
            if (language == 'ar') {
                $('body').addClass('rtl rtl-style')
                $('body').removeClass('ltr-style')
                $('.card-body').removeClass('pr-0')
            } else {
                $('body').removeClass('rtl rtl-style')
                $('body').addClass('ltr-style')
                $('.card-body').addClass('pr-0 ')
            }

            // ----- TESTING --------------
            if (this.$i18n.locale == language) return
            let currentUserId = store._modules.root.context.state.auth.userId
            axios({
                method: "post",
                url: '/api/changelang',
                data: {
                    language: language,
                    user_id: currentUserId
                },
                headers: {
                    Authorization: `Bearer ${store.getters["auth/getAdminToken"]}`,
                },
            }).then(res => {
                if (language == 'ar') {
                    $('body').addClass('rtl rtl-style')
                    $('body').removeClass('ltr-style')
                    $('.card-body').removeClass('pr-0')
                } else {
                    $('body').removeClass('rtl rtl-style')
                    $('body').addClass('ltr-style')
                    $('.card-body').addClass('pr-0 ')
                }
                this.$i18n.locale = language;

                // this.redirectWindowTo("/"); 

                // window.location.href = window.location.origin
                // this.$router.push("/dedicated")

            }).catch((catchError) => {
                // this.handleResponseError(catchError)
            })


        },
        acceptNumbersAndDots(e) {
            let val = $(e.target).val();
            if (val.match(/[^0-9.]/g)) {
                $(e.target).val(val.replace(/[^0-9.]/g, ""));
            }
        },


    },
}
Vue.mixin(mixins)

new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),

}).$mount("#app");
