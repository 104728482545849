


import { getField, updateField } from 'vuex-map-fields';

const state = {
    appName: "",
    appPackageName: "",
    appVersionName: "",
    appVersionCode: "",
    appSelectedCategory: "",
    appDescription: ""
}


const getters = {
    getField
}

const actions = {
}
const mutations = {
    updateField
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
